<template>

  <div class="row" ref="websiteClientForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="title"
              rules="required"
              name="The Title"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Title"
                        name="title"
                        fou
                        v-model="formData.title">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="website"
              rules=""
              name="The Website"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Website"
                        name="website"
                        fou
                        v-model="formData.website">
              </fg-input>
            </ValidationProvider>
            <div class="form-group">
              <label>Image</label>
              <el-tooltip placement="right" v-if="false">
                <div slot="content"></div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                v-model="formData.image"
              >
              </prime-uploader>
            </div>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="default" wide>Save
            </l-button>
            <l-button @click="$router.push('/website-clients/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import PrimeUploader from "../../components/PrimeUploader";
import LSwitch from "../../components/Switch";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {LSwitch, PrimeUploader},
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        website: "",
        image: '',
        is_active: true
      },
    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.websiteClientForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Website Client";
      this.getWebsiteClient();
    } else {
      this.editMode = false;
      this.formTitle = "Add Website Client";
      this.loader.hide();
    }

  },
  methods: {
    getWebsiteClient() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("website-clients/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Website Client Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit(isSave = false) {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("website-clients/update/" + this.id, this.formData);
        successMessage = "Website Client Updated Successfully";
      } else {
        request = this.axios.post("website-clients/create", this.formData);
        successMessage = "Website Client Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        if (isSave){
          window.location.href = "/website-clients/edit/" + response.data.id;
        }else{
          this.$router.push("/website-clients/list");
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>

<style>
</style>
